@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

// GDDS Headlines
.gddsHeadlineH2 {
  h2 {
    font-size: 1.625rem;
    line-height: 1.23076923077;
    margin-bottom: 2.5rem;

    @include screen-sm {
      font-size: 1.75rem;
      line-height: 1.25;
    }

    @include screen-lg {
      font-size: 2rem;
      margin-bottom: 3rem;
    }
  }
}

.headline {
  margin-bottom: 3rem;

  @include screen-sm {
    margin-bottom: 2.5rem;
  }
}

.table {
  width: 100%;

  .oneFourth th {
    width: 25%;
  }

  .oneFifth th {
    width: 20%;
  }

  tr {
    th {
      line-break: auto;
    }

    td {
      font-size: 0.875rem;
      line-break: auto;
      vertical-align: middle;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.accordionItem {
  margin-bottom: 0.5rem;
}

.accordionBody {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1rem 1rem 0;

  button {
    align-self: flex-start;
    flex-shrink: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
    width: 100%;

    @include screen-sm {
      margin-bottom: 0;
      align-self: flex-start;
      padding-right: 2rem;
    }

    strong {
      font-weight: $font-weight-bold;
      display: block;
    }
  }

  @include screen-sm {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 3.5rem 1rem 0;

    &.oneThird > div {
      max-width: 33%;
    }
    &.twoThirds > div {
      max-width: 66%;
    }
  }
}

:global(h4.gdds-element) {
  margin: 0;
}
