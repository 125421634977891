@use 'sass:math';

@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

$circle-width: 5.5rem;
$circle-width-mobile: 3.75rem;

.progressBar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $circle-width-mobile;
  margin: 0 1.5rem 0 0;

  @include screen-sm {
    width: $circle-width;
    margin: 0 2rem 0 0.5rem;
  }

  @include screen-md {
    margin: 0 2.5rem 0 1.5rem;
  }
}

.progressCircleWrapper {
  position: relative;
  background-color: $gb_grey_075;
  width: $circle-width-mobile;
  height: $circle-width-mobile;
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    display: block;
    border-radius: 50%;
    background-color: $gb_grey_050;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(#{$circle-width-mobile} - 6px);
    height: calc(#{$circle-width-mobile} - 6px);
  }

  &.locked {
    &:after {
      background-color: $gb_white;
    }

    svg {
      fill: $gb_grey_150;
    }
  }

  @include screen-sm {
    width: $circle-width;
    height: $circle-width;

    &:after {
      width: calc(#{$circle-width} - 0.5rem);
      height: calc(#{$circle-width} - 0.5rem);
    }
  }
}

.pie {
  position: absolute;
  border: 3px solid $gb_gdds_warning;
  width: $circle-width-mobile;
  height: $circle-width-mobile;
  clip: rect(0rem, math.div($circle-width-mobile, 2), $circle-width-mobile, 0rem);
  border-radius: 50%;
  transform: rotate(0deg);

  @include screen-sm {
    width: $circle-width;
    height: $circle-width;
    clip: rect(0rem, math.div($circle-width, 2), $circle-width, 0rem);
    border: 4px solid $gb_gdds_warning;
  }
}

.pieFill {
  transform: rotate(180deg);
}

.slice {
  position: absolute;
  width: $circle-width-mobile;
  height: $circle-width-mobile;
  clip: rect(0rem, $circle-width-mobile, $circle-width-mobile, math.div($circle-width-mobile, 2));

  @include screen-sm {
    width: $circle-width;
    height: $circle-width;
    clip: rect(0rem, $circle-width, $circle-width, math.div($circle-width, 2));
  }
}

.bar {
  @extend .pie;
}

.fill {
  border-color: $gb_gdds_warning;
}

.rectAuto {
  clip: rect(auto, auto, auto, auto);
}

// loop to create all needed elements automatically
@for $j from 51 through 99 {
  :global(.p#{$j}) .slice {
    @extend .rectAuto;
  }

  :global(.p#{$j}) .bar:after {
    @extend .pieFill;
  }

  :global(.p#{$j}) .fill {
    @extend .pie;
    @extend .pieFill;
  }
}

@for $j from 1 through 100 {
  :global(.p#{$j}) .bar {
    transform: rotate((math.div(360, 100) * $j) + deg);
  }
}

:global(.p100) {
  .bar {
    @extend .rectAuto;
    border-color: $gb_gdds_success;
  }

  .slice {
    @extend .rectAuto;
  }
}

.progressPercentage {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.125rem;

  @include screen-sm {
    font-size: 1.25rem;
  }
}
