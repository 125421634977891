@import '../../checkout.module.scss';

.h1 {
  @include word-wrap();
  @include set-responsive-font-sizes(31, 35, 42);
  margin-bottom: $spacing-xl;
  margin-top: $spacing-xl;

  @include below-screen-sm {
    margin-top: $spacing-m;
    line-height: 2.5rem;
  }
}

.explanationText {
  max-width: $gb_containerMaxWidth;
}

.bottomMargin {
  margin-bottom: $spacing-xxxl !important;
}
